import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'

createApp(App).use(store, key).use(router).mount('#app')

declare global {
    interface Window {
        sa_event: (eventName: string, callback?: () => void) => void;
        logEvent: (eventName: string, callback?: () => void) => void;
    }
}

window.logEvent = function(eventName, callback?) {
    console.log(`Logging event: ${eventName}`);
    window.sa_event(eventName, callback);
}

if(process.env.VUE_APP_ENVIRONMENT !== "development") {
    const script = document.createElement("script");
    script.src = "https://scripts.simpleanalyticscdn.com/latest.js";
    script.defer = true;
    document.body.append(script);
} else {
    const script = document.createElement("script");
    script.src = "https://scripts.simpleanalyticscdn.com/latest.js";
    script.defer = true;
    script.setAttribute('data-hostname', 'dev.konyawoo.com');
    document.body.append(script);
}
