import Vue from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import ProductPage from '@/views/ProductPage.vue'
import ForArtists from '@/views/ForArtists.vue'
import ForCreators from '@/views/ForCreators.vue'
import ForFans from '@/views/ForFans.vue'
import Cart from '@/views/Cart.vue'
import Success from '@/views/Success.vue'
import BrandPage from '@/views/BrandPage.vue'
import Brands from '@/views/Brands.vue'

const routes: Array<RouteRecordRaw> = [{
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/about',
    name: 'About',
    meta: {
      title: 'Konyawoo - about',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path:'/product/:product_id',
    name:'product',
    component: ProductPage,
    props: true,
    meta: {
      title: 'Konyawoo - product',
    },
  }, {
    path:'/brand/:brand_id',
    name:'brand',
    component: BrandPage,
    props: true,
    meta: {
      title: 'Konyawoo - brand',
    },
  }, {
    path:'/brands',
    name:'brands',
    component: Brands,
    props: true,
    meta: {
      title: 'Konyawoo - brands',
    },
  },{
    path: '/for_artists',
    name: "ForArtists",
    component: ForArtists,
  }, {
    path: '/for_creators',
    name: "ForCreators",
    component: ForCreators,
  }, {
    path: '/for_fans',
    name: "ForFans",
    component: ForFans,
  }, {
    path: '/cart',
    name: "Cart",
    component: Cart,
    meta: {
      title: 'Konyawoo - cart',
    },
  }, {
    path: '/success',
    name: "Success",
    component: Success,
    meta: {
      title: 'Konyawoo - success',
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  /*
  let locationPrefix = "";
  if(<any>process.env.VUE_APP_ENVIRONMENT !== "production") {
    locationPrefix = `[${(process.env.VUE_APP_ENVIRONMENT as string).toUpperCase()}]`
  }
  (document.title as any) = `${locationPrefix} ${to.meta.title || "Konyawoo"}`;
  */
});

export default router
