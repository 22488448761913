
import { Options, Vue } from 'vue-class-component';
@Options({
    props: ["brand"],
    methods: {
        brandNavigate(brand_id:string) {
            window.logEvent("brand_card_navigate");
            this.$router.push({ name:'brand', params:{ brand_id:brand_id } })
        },
    },
    computed: {
        brandImage() {
            return `${process.env.VUE_APP_ASSET_HOST}${this.brand.images[0]}.200x200.png8.png`
        },
    },
    components: {
    },
})
export default class BrandCard extends Vue {}
