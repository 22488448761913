
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store'

@Options({
    props: ["product_id"],
    data() {
        return {
            curImage: 0,
            showWebm: false,
            product: null,
            brands: null,
        }
    },
    created() {
        this.fetchData();
    },
    computed: {
        artistIsArray() {
            return typeof this.product.artist_url === "object";
        },
        getImagesAdjusted() {
            if (["bundle", "sticker-bundle"].includes(this.product.product_type)) return this.product.images;
            return [
                this.product.images[0],
                [this.product.images[0], "webm"],
                ...this.product.images.slice(1)
            ];
        },
        cartCount() {
            const store = useStore()
            const count = Object.values(store.state.cart).reduce((item, acc) => acc + item, 0);
            return count ? `${count} Item${count > 1 ? "s" : ""}` : "Empty";
        },
        animHandler() {
            return this.cartCount === "Empty" ? "button-close" : "button-open";
        },
        connectedBrands() {
            if(!this.product && !this.brands) return [];
            const allBrands = [this.product.brand_id, ...this.product.alt_brands];
            return allBrands.map(brandID => this.brands.find((obj:{[key: string]: any}) => brandID == obj.id))
        }
    },
    methods: {
        brandNavigate(brand_id:string) {
            window.logEvent('product_navigate_to_brand');
            this.$router.push({name:'brand', params:{brand_id:brand_id}});
        },
        cartNavigate() {
            window.logEvent('product_navigate_to_cart');
            this.$router.push('/cart');
        },
        handleImage() {
            if(this.getImagesAdjusted[this.curImage][1] === "webm") {
                this.showWebm = true;
            }
        },
        galleryImage(which:string) {
            if(which[1] === "webm") return `${process.env.VUE_APP_ASSET_HOST}${which[0]}-preview.124x124.png8.png`
            return `${process.env.VUE_APP_ASSET_HOST}${which}.124x124.png8.png`
        },
        productImage(which:string) {
            if(which[1] === "webm") return `${process.env.VUE_APP_ASSET_HOST}${which[0]}-preview.512x512.png8.png`
            return `${process.env.VUE_APP_ASSET_HOST}${which}.512x512.png8.png`
        },
        interpolateAsset(which:string) {
            return `${process.env.VUE_APP_ASSET_HOST}${which}`;
        },
        addToCart() {
            window.logEvent('product_added_to_cart');
            this.$store.commit('addToCart', this.product_id);
        },
        imgClick(index:number) {
            this.showWebm = false;
            this.curImage = index;
        },
        fetchData() {
            this.error = this.post = null;
            this.loading = true;
            this.$store.dispatch('getProduct', this.product_id).then((data:any) => {
                this.loading = false;
                this.product = data;
            });
            this.$store.dispatch('getBrands').then((data:any) => {
                this.loading = false;
                this.brands = data;
            });
        }
    },
    components: {
    },
})
export default class ProductPage extends Vue {}
