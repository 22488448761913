import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f078a6a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "brand-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    role: "link",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.brandNavigate(_ctx.brand.id))),
    class: "brand"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "brand-image",
        src: _ctx.brandImage
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.brand.name), 1)
  ]))
}