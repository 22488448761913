import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "img-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "product-name" }
const _hoisted_4 = { class: "product-price" }
const _hoisted_5 = { class: "persona" }
const _hoisted_6 = { class: "avatar-container" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    role: "link",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.productNavigate(_ctx.product.id))),
    class: "product"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "product-image",
        src: _ctx.productImage
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.product.name), 1),
    _createElementVNode("div", _hoisted_4, "USD$" + _toDisplayString((_ctx.product.price / 100).toFixed(2)), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.product.brand), 1),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        class: "avatar-image",
        src: _ctx.brandImage
      }, null, 8, _hoisted_7)
    ])
  ]))
}