
import { Options, Vue } from 'vue-class-component';
import Product from '@/components/ProductCard.vue';
@Options({
    props: ["brand_id"],
    data() {
        return {
            brand: null,
            products: null,
        }
    },
    computed: {
        getHeader() {
            if(!this.brand) return {};
            return {
                backgroundImage: `url("${process.env.VUE_APP_ASSET_HOST}${this.brand.header_image}")`,
            };
        },
        brandProducts() {
            if(!this.brand) return null;
            if(!this.products) return null;
            return this.products.filter((item:any) => item.brand_id === this.brand.id || item.alt_brands.includes(this.brand.id))
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.error = this.post = null;
            this.loading = true;
            this.$store.dispatch('getProducts').then((data:any) => {
                this.loading = false;
                this.products = data;
            })
            this.$store.dispatch('getBrand', this.brand_id).then((data:any) => {
                this.brand = data;
            });
        }
    },
    components: {
        Product,
    },
})
export default class BrandPage extends Vue {}
