import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79da6a0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "header" }
const _hoisted_2 = { id: "home" }
const _hoisted_3 = { id: "home-content" }
const _hoisted_4 = {
  key: 0,
  class: "loading"
}
const _hoisted_5 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Product = _resolveComponent("Product")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        id: "header-content",
        style: _normalizeStyle(_ctx.getHeader)
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Loading... "))
          : _createCommentVNode("", true),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandProducts, (product) => {
          return (_openBlock(), _createBlock(_component_Product, {
            key: product.id,
            product: product
          }, null, 8, ["product"]))
        }), 128))
      ])
    ])
  ], 64))
}