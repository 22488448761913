
import { Options, Vue } from 'vue-class-component';
@Options({
    props: ["product"],
    methods: {
        productNavigate(product_id:string) {
            window.logEvent("product_card_navigate");
            this.$router.push({ name:'product', params:{ product_id:product_id } })
        },
    },
    computed: {
        productImage() {
            return `${process.env.VUE_APP_ASSET_HOST}${this.product.images[0]}.200x200.png8.png`
        },
        brandImage() {
            return `${process.env.VUE_APP_ASSET_HOST}${this.product.brand_asset}`
        }
    },
    components: {
    },
})
export default class ProductCard extends Vue {}
