
import { Options, Vue } from 'vue-class-component';
import { useStore } from './store'

@Options({
  computed: {
    environment() {
      return process.env.VUE_APP_ENVIRONMENT;
    },
    cartCount() {
      const store = useStore()
      const count = Object.values(store.state.cart).reduce((item, acc) => acc + item, 0);
      // Non-breaking space below.
      return count ? `${count}\xa0Item${count > 1 ? "s" : ""}` : "Empty";
    },
  }
})
export default class App extends Vue {}
