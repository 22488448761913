
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store'

@Options({
    data() {
        return {
            productInfo: {},
            loadingCount: null,
        }
    },
    created() {
        this.loadingCount = 0;
        for(let key in this.cartItems) {
            this.productInfo[key] = false;
            this.loadingCount++;
            this.fetchData(key);
        }
    },
    computed: {
        shippingValue() {
            const SHIP_PRICES:{[key:string]: number} = {
                keychain: 500,
                sticker: 100,
                bundle: 500,
                "sticker-bundle": 100,
            }
            let cost = 0;
            for(let key in this.cartItems) {
                if(this.cartItems[key] === 0) continue;
                cost = Math.max(cost, SHIP_PRICES[this.productInfo[key].product_type]);
            }
            return cost;
        },
        totalValue() {
            let sum = 0;
            for(let key in this.cartItems) {
                sum += this.cartItems[key] * this.productInfo[key].price;
            }
            return sum;
        },
        cartItems() {
            const store = useStore()
            return store.state.cart;
        },
    },
    methods: {
        galleryImage(which:string) {
            return `${process.env.VUE_APP_ASSET_HOST}${which}.124x124.png8.png`
        },
        fetchData(id:string) {
            fetch(`${process.env.VUE_APP_ROOT_API}/api/product/${id}`).then(res => res.json()).then(data => {
                this.productInfo[id] = data;
                this.loadingCount--;
            })
        },
        checkout() {
            const targetKeys = Object.keys(this.cartItems);
            if(targetKeys.length === 0) return false;
            fetch(`${process.env.VUE_APP_ROOT_API}/api/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': `${process.env.VUE_APP_ROOT_API}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.cartItems),
            }).then(res => res.json()).then(data => {
                if(data && data.url) {
                    window.location.href = data.url;
                }
            })
        },
        decreaseQuantity(id:string) {
            this.$store.commit('decreaseQuantity', id);
        },
        increaseQuantity(id:string) {
            this.$store.commit('addToCart', id);
        }
    }
})
export default class Cart extends Vue {}
