
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store'

@Options({
     data() {
        return {
            successCart: {},
            products: null,
        }
    },
    methods: {
        getImage(key:string) {
            console.log(this.products);
            return `${process.env.VUE_APP_ASSET_HOST}${this.products.find((item:any) => item.id === key).images[0]}.124x124.png8.png`
        },
        fetchData() {
            this.$store.dispatch('getProducts').then((data:any) => {
                this.products = data;
            })
        }
    },
    created() {
        this.fetchData();
        const store = useStore()
        for(let key in store.state.cart) {
            this.successCart[key] = store.state.cart[key];
        }
        store.commit('emptyCart');
    },
})
export default class Success extends Vue {}
