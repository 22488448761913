import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'

// define your typings for the store state
export interface State {
  cart: {[key: string]: number},
  products: CacheObject,
  brands: CacheObject,
  product: {[key: string]: CacheObject},
  brand: {[key: string]: CacheObject},
}

export interface CacheObject {
  date: Date | null,
  value: {[key: string]: any} | null,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    cart: JSON.parse(localStorage.getItem('cart') ?? "{}"),
    products: {
      date: null,
      value: null,
    },
    brands: {
      date: null,
      value: null,
    },
    product: {},
    brand: {},
  },
  mutations: {
    addToCart(state, key) {
      if(!state.cart[key]) state.cart[key] = 0;
      state.cart[key]++;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    decreaseQuantity(state, key) {
      if(state.cart[key] === undefined || state.cart[key] === null) return;
      state.cart[key]--;
      if(state.cart[key] < 0) delete state.cart[key];
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    emptyCart(state) {
      state.cart = {};
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    updateProducts(state, products) {
      state.products.value = products;
      state.products.date = new Date();
    },
    updateBrands(state, brands) {
      state.brands.value = brands;
      state.brands.date = new Date();
    },
    updateProduct(state, {product, key}) {
      state.product[key] = {
        date: new Date(),
        value: product,
      };
    },
    updateBrand(state, {brand, key}) {
      state.brand[key] = {
        date: new Date(),
        value: brand,
      };
    }
  },
  actions: {
    async getProducts({state, commit}) {
      if(verifyCache(state.products)) return state.products.value;
      const products = shuffle(await fetch(`${process.env.VUE_APP_ROOT_API}/api/products`).then(res => res.json()));
      commit('updateProducts', products);
      return products;
    },
    async getBrands({state, commit}) {
      if(verifyCache(state.brands)) return state.brands.value;
      const brands = shuffle(await fetch(`${process.env.VUE_APP_ROOT_API}/api/brands`).then(res => res.json()));
      commit('updateBrands', brands);
      return brands;
    },
    async getProduct({state, commit}, key) {
      if(state.product[key] !== undefined && verifyCache(state.product[key])) return state.product[key].value;
      const product = await fetch(`${process.env.VUE_APP_ROOT_API}/api/product/${key}`).then(res => res.json())
      commit('updateProduct', {product, key});
      return product;
    },
    async getBrand({state, commit}, key) {
      if(state.brand[key] !== undefined && verifyCache(state.brand[key])) return state.brand[key].value;
      const brand = await fetch(`${process.env.VUE_APP_ROOT_API}/api/brand/${key}`).then(res => res.json());
      commit('updateBrand', {brand, key});
      return brand;
    }
  },
  modules: {
  }
})

function shuffle(arr:[]) {
  let currentIndex = arr.length, randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }
  return arr;
}

function emptyObject(obj:any):boolean {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

function verifyCache(obj:CacheObject) {
  return obj.value !== null && !emptyObject(obj.value) && obj.date !== null && (Date.now() - obj.date.getTime()) < 1000 * 60 * 5;
}

export function useStore () : Store<State> {
  return baseUseStore(key)
}
