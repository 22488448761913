
import { Options, Vue } from 'vue-class-component';
import Product from '@/components/ProductCard.vue';
@Options({
  data() {
    return {
      loading: null,
      error: null,
      products: null,
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      this.loading = true;
      this.$store.dispatch('getProducts').then((data:any) => {
        this.loading = false;
        this.products = data;
      })
    }
  },
  components: {
    Product,
  },
})
export default class Home extends Vue {}
