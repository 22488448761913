
import { Options, Vue } from 'vue-class-component';
import Brand from '@/components/BrandCard.vue';
@Options({
    data() {
        return {
            brands: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.error = this.post = null;
            this.loading = true;
            this.$store.dispatch('getBrands').then((data:any) => {
                this.loading = false;
                this.brands = data;
            });
        }
    },
    components: {
      Brand,
    },
})
export default class Brands extends Vue {}
